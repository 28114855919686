import { Button, Form, Modal } from "react-bootstrap";
import React from "react";
import style from "./ModalPadrao.module.css";
import { EpiContext } from "../Hooks/EpiContext";
import { BASE_URL_API } from "../config.url";

const ModalPadrao = ({ titulo, texto, mostrar }) => {
  const [show, setShow] = React.useState(true);
  const global = React.useContext(EpiContext);

  const handleClose = () => {
    setShow(false);
    global.setModal(false);
  };
  function handleSubmit() {
    if (global.dataSource.length >= 1) {
      global.dataSource.map((item) => {
        delete item.key;
      });
      global.dataSource.shift();
      const body = JSON.stringify(global.dataSource);
      fetch(`${BASE_URL_API}/ficha/epi/inserir`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
          "x-acess-token": localStorage.getItem("token"),
        },
        body: body,
      })
        .then((response) => response.json())
        .then((json) => {
          alert(json);
          window.location.href = "/movimentacao/epi/funcionario";
        });
    }
  }
  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{titulo}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{texto}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Não confirmo
        </Button>
        <Form onSubmit={handleSubmit}>
          <Button variant="primary" onClick={handleSubmit}>
            Confirmo entrega
          </Button>
        </Form>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPadrao;
