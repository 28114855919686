import React from "react";
import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
// import styles from "./Menu.module.css";
import { ReactComponent as Cadastros } from "./images/1.svg";
import { ReactComponent as Movimentacoes } from "./images/2.svg";
import { ReactComponent as Relatorios } from "./images/3.svg";
import { ReactComponent as Dashboards } from "./images/7.svg";
import Logo from "./images/logo-horizontal.png";

import { NavLink, useNavigate } from "react-router-dom";
import Botao from "./Botao/Botao";

const Menu = () => {
  const [activeCadastro, setActiveCadastro] = React.useState(true);
  const [activeMovimentacao, setActiveMotivmentacao] = React.useState(true);
  const [activeRelatorio, setActiveRelatorio] = React.useState(true);
  const [activeDashboard, setActiveDashboard] = React.useState(true);
  const [tamanhoTela, setTamanhoTela] = React.useState("");

  const altura = document.body.scrollHeight;
  const navigate = useNavigate();

  React.useMemo(() => {
    setTamanhoTela(altura);
  }, [altura]);

  const objMenu = [
    {
      nomeObj: "Cadastros",
      fotoObj: <Cadastros />,
      isActive: activeCadastro,
      listaAberta: [
        "Cadastro de Funcionário",
        "Cadastro de EPI",
        "Cadastro de Risco",
      ],
      paginas: ["/funcionarios", "/epi", "/risco"],
    },
    {
      nomeObj: "Movimentação",
      fotoObj: <Movimentacoes />,
      isActive: activeMovimentacao,
      listaAberta: ["Entrega de EPI", "Movimentação de Estoque"],
      paginas: ["/movimentacao/epi/funcionario", "/estoque"],
    },
    {
      nomeObj: "Relatórios",
      fotoObj: <Relatorios />,
      isActive: activeRelatorio,
      listaAberta: ["Entrega de EPI", "Ficha de EPI"],
      paginas: ["/entrega", "/ficha/epi/funcionario"],
    },
    {
      nomeObj: "Dashboards",
      fotoObj: <Dashboards />,
      isActive: activeDashboard,
      listaAberta: ["Dashboards"],
      paginas: ["/hdashboards"],
    },
  ];

  function handleClickMenu(event) {
    const string = event.target.innerText.trim().split("\n");
    let stringEventoTratado = string[0];
    const objAlterar = objMenu.find((item) => {
      if (item.nomeObj.trim() === stringEventoTratado) {
        return item.nomeObj.trim() === stringEventoTratado;
      }
    });
    if (objAlterar === undefined) return null;
    switch (objAlterar.nomeObj) {
      case "Cadastros":
        setActiveCadastro(!activeCadastro);
        break;
      case "Movimentação":
        setActiveMotivmentacao(!activeMovimentacao);
        break;
      case "Relatórios":
        setActiveRelatorio(!activeRelatorio);
        break;
      case "Dashboards":
        setActiveDashboard(!activeDashboard);
        break;
      default:
        break;
    }
  }

  function logout() {
    localStorage.removeItem("token");
    navigate("/");
  }

  return (
    <Navbar expand="lg" bg="dark" variant="dark" className="p-2">
      <Container>
        <Navbar.Brand href="/movimentacao/epi/funcionario" className="w-25">
          <img src={Logo} className="img-fluid w-75" alt="Logo da Rigbie." />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="menu" />
        <Navbar.Collapse id="menu">
          <Nav className="m-auto text-white">
            {objMenu.map((item, index) => {
              return (
                <NavDropdown key={index} title={item.nomeObj} id={item.nomeObj}>
                  {item.listaAberta.map((lista, i) => (
                    <NavDropdown.Item key={lista} href={item.paginas[i]}>
                      {lista}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              );
            })}
          </Nav>
          <Button variant="danger" onClick={logout}>
            Logout
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;
