import React from "react";
import style from "./MovimentacaoEstoque.module.css";
import { Form, Col, Row, FloatingLabel, Container } from "react-bootstrap";
import Botao from "../Botao/Botao";
import { BASE_URL_API } from "../config.url";
import Menu from "../Menu";

const MovimentacaoDeEstoque = () => {
  const [id, setId] = React.useState(0);
  const [data, setData] = React.useState("");
  const [update, setUpdate] = React.useState(false);

  React.useEffect(() => {
    fetch(`${BASE_URL_API}/epi/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
        "x-acess-token": localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if(json != undefined || json != null){
          setUpdate(true);
        }else{
          setUpdate(false);
        }
        setData(json);
      });
    
  }, [id]);

  function handleSubmit(event) {
    data["cd_epi_emp"] = id;
    let body = JSON.stringify(data);
    if(update){
      fetch(`${BASE_URL_API}/epi/editar/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
          "x-acess-token": localStorage.getItem("token"),
        },
        body: body,
      })
        .then((response) => response.json())
        .then((json) => setData(json));
    }else{
      fetch(`${BASE_URL_API}/epi/criar`, {
        method: "POST",
        headers:{
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${BASE_URL_API}/`,
          "x-acess-token": localStorage.getItem("token"),
        },
        body: body
      })
    }
  }

  return (
    <>
      <Menu />
      <Container>
        <Row>
          <Col sm>
            <h1 className="text-center mt-4 mb-4">IDENTIFICACAO DO ESTOQUE</h1>
          </Col>
        </Row>

        <Row>
          <Col sm>
            <Form className={style.formulario} onSubmit={handleSubmit}>
              <Row className="g-2 mb-3">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="CÓDIGO EPI"
                  >
                    <Form.Control
                      onChange={({ target }) =>
                        target.value !== "" ? setId(target.value) : 0
                      }
                      type="text"
                      placeholder="1"
                    />
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Descrição EPI"
                  >
                    <Form.Control
                      onChange={({ target }) =>
                        setData({...data, desc_epi: target.value })
                      }
                      value={data ? data.desc_epi : ""}
                      type="text"
                      placeholder="1"
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="g-2 mb-3">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Parte do Corpo"
                  >
                    <Form.Control
                      onChange={({ target }) =>
                        setData({...data, nm_parte_corpo: target.value })
                      }
                      value={data ? data.nm_parte_corpo : ""}
                      type="text"
                      placeholder="2"
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="g-2 mb-3">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Quantidade"
                  >
                    <Form.Control
                      onChange={({ target }) =>
                        setData({...data, qt_estoque: target.value })
                      }
                      value={data ? data.qt_estoque : ""}
                      type="text"
                      placeholder="2"
                    />
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel controlId="floatingInputGrid" label="CA">
                    <Form.Control
                      onChange={({ target }) =>
                        setData({...data, num_ca: target.value })
                      }
                      value={data ? data.num_ca : ""}
                      type="text"
                      placeholder="2"
                    />
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Valor unitário"
                  >
                    <Form.Control
                      onChange={({ target }) =>
                        setData({...data, vl_unitario: target.value })
                      }
                      value={data ? data.vl_unitario : ""}
                      type="text"
                      placeholder="2"
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col md>
                  <Botao label={"Atualizar"} />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MovimentacaoDeEstoque;
