// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Botao_button__hJk02{\n  font-size: 1rem;\n  font-family: var(--type-first);\n  cursor: pointer;\n  border: none;\n  border-radius: 0.4rem;\n  background: rgb(234,146, 0);\n  color: black;\n  padding: .8rem 1.2rem;\n  box-sizing: border-box;\n  min-width: 8rem;\n  transition: 1s;\n}\n\n\n.Botao_button__hJk02:hover,\n.Botao_button__hJk02:focus{\n  outline: none;\n  box-shadow: 0 0 0 3px #fea, 0 0 0 4px rgb(240,130, 20);\n}\n\n.Botao_button__hJk02:disabled{\n  opacity: 0.5rem;\n  cursor: wait;\n}", "",{"version":3,"sources":["webpack://./src/Botao/Botao.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,8BAA8B;EAC9B,eAAe;EACf,YAAY;EACZ,qBAAqB;EACrB,2BAA2B;EAC3B,YAAY;EACZ,qBAAqB;EACrB,sBAAsB;EACtB,eAAe;EACf,cAAc;AAChB;;;AAGA;;EAEE,aAAa;EACb,sDAAsD;AACxD;;AAEA;EACE,eAAe;EACf,YAAY;AACd","sourcesContent":[".button{\n  font-size: 1rem;\n  font-family: var(--type-first);\n  cursor: pointer;\n  border: none;\n  border-radius: 0.4rem;\n  background: rgb(234,146, 0);\n  color: black;\n  padding: .8rem 1.2rem;\n  box-sizing: border-box;\n  min-width: 8rem;\n  transition: 1s;\n}\n\n\n.button:hover,\n.button:focus{\n  outline: none;\n  box-shadow: 0 0 0 3px #fea, 0 0 0 4px rgb(240,130, 20);\n}\n\n.button:disabled{\n  opacity: 0.5rem;\n  cursor: wait;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Botao_button__hJk02"
};
export default ___CSS_LOADER_EXPORT___;
