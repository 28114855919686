import React from "react";
import { Table, Container, Row, Col, Button } from "react-bootstrap";
import { EpiContext } from "../Hooks/EpiContext";
import style from "../Botao/Botao.module.css";
import ModalPadrao from "../Modais/ModalPadrao";

function Tabela() {
  const global = React.useContext(EpiContext);

  function handleAtualizaValores(idLinha) {
    global.setDataSource(
      global.dataSource.filter((item) => item.key !== idLinha)
    );
  }

  function handleOpenModal() {
    global.setModal(true);
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>Qntd.</th>
                  <th>Data de Entrega</th>
                  <th>Excluir</th>
                </tr>
              </thead>
              <tbody>
                {global.dataSource.length &&
                  global.dataSource.map((item, indice) => {
                    return indice > 0 ? (
                      <tr key={indice}>
                        <td key={item.desc_epi}>{item.desc_epi}</td>
                        <td key={item.qt_entregue}>{item.qt_entregue}</td>
                        <td key={item.dt_assinatura}>
                          {item.dt_assinatura.split("-").reverse().join("/")}
                        </td>
                        <td>
                          <Button
                            className="btn btn-danger"
                            onClick={() => handleAtualizaValores(item.key)}
                          >
                            Excluir
                          </Button>
                        </td>
                      </tr>
                    ) : (
                      ""
                    );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <button className={style.button} onClick={handleOpenModal}>
              Confirma entrega?
            </button>
            {global.modal && (
              <ModalPadrao
                className="m-0 p-0"
                titulo={"Confirmação de entrega"}
                texto={"Confirma a entrega destes EPI'S?"}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Tabela;
